import { useNavigate, useParams } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../../../configs/firebaseConfig";
import ChatFeed from "../../../private-module/components/organisms/chat-feed/ChatFeed";
import DashboardTemplate from "../../../private-module/components/templates/dashboard-template/DashboardTemplate";
import { LawModel } from "../../../models/LawModel";
import { useEffect, useState } from "react";
import ListOfSources from "../../../private-module/components/organisms/right-side-bar/components/list-of-sources/ListOfSources";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
  Popover,
  Select,
  Typography,
} from "@mui/material";
import {
  ConversationData,
  UserElement,
} from "../admin-users-page/UserDetailsPanel";
import colors from "../../../configs/colorConfig";
import moment from "moment";

const AdminConversationPage = () => {
  const { id } = useParams();
  const [sources, setSources] = useState<LawModel[]>([]);
  const [users, setUsers] = useState<UserElement[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserElement | null>(null);
  const [selectedConvo, setSelectedConvo] = useState<ConversationData | null>(
    null
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const [popoverVisible, setPopoverVisible] = useState(false);
  const [conversations, setConversations] = useState<ConversationData[]>([]);
  const navigate = useNavigate();

  const getUsersList = async () => {
    const querySnapshot = await getDocs(query(collection(firestore, "Users")));

    const usersList: UserElement[] = querySnapshot.docs.map((doc) => {
      return {
        email: doc.data().email,
        id: doc.id,
        name: "",
      };
    });

    setUsers(usersList);
    return usersList;
  };

  const handleButtonClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setPopoverVisible(!popoverVisible);
  };

  const handleClose = () => {
    setPopoverVisible(false);
    setAnchorEl(null);
  };

  const getUserDetails = async (id: string) => {
    const q = query(
      collection(firestore, "Conversations"),
      where("uid", "==", id)
    );

    const querySnapshot = await getDocs(q);
    const conversationsList: ConversationData[] = querySnapshot.docs.map(
      (doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          lastAppended: data.last_appended ? data.last_appended.toDate() : null,
          snippet: data.messages[1].content,
        };
      }
    );

    // Sort conversationsList by lastAppended
    conversationsList.sort((a, b) => {
      if (a.lastAppended && b.lastAppended) {
        return b.lastAppended.getTime() - a.lastAppended.getTime();
      } else if (a.lastAppended) {
        return -1;
      } else if (b.lastAppended) {
        return 1;
      } else {
        return 0;
      }
    });

    setConversations(conversationsList);
  };

  const getConversationUid = async (conversationId: string) => {
    const conversationDoc = await getDoc(
      doc(firestore, "Conversations", conversationId)
    );
    if (conversationDoc.exists()) {
      const conversationData = conversationDoc.data();
      setSelectedConvo({
        id: conversationId,
        lastAppended: conversationData.last_appended
          ? conversationData.last_appended.toDate()
          : null,
        snippet: conversationData.messages[1].content,
      });
      return conversationData.uid;
    }
    return null;
  };

  useEffect(() => {
    const fetchData = async () => {
      const list = await getUsersList();

      if (id) {
        const uid = await getConversationUid(id);
        if (uid) {
          setSelectedUser(list.find((user) => user.id === uid) ?? null);
        }
      }
    };

    fetchData();

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedUser != null) {
      getUserDetails(selectedUser.id).then((user) => {
        // setSelectedUser(user);
      });
    }
    //eslint-disable-next-line
  }, [selectedUser]);

  return (
    <div>
      <div
        style={{
          position: "absolute",
          right: "0px",
          top: "0px",
          background: colors.primary,
          display: "flex",
          padding: "10px",
          border: "1px solid #ccc",
        }}
      >
        <Box width={"200px"} mr={"10px"}>
          <Select
            size="small"
            fullWidth
            value={selectedUser?.email || ""}
            defaultValue={selectedUser?.email || ""}
          >
            {users.map((user) => (
              <MenuItem
                key={user.id}
                value={user.email}
                onClick={() => setSelectedUser(user)}
              >
                {user.email}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box width={"200px"}>
          <Button
            fullWidth
            onClick={handleButtonClick}
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.23)",
              borderRadius: "4px",
              textTransform: "none",
            }}
          >
            {moment(selectedConvo?.lastAppended).format("HH:mm DD.MM.YYYY")}
          </Button>
          <Popover
            open={popoverVisible}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              maxHeight: "700px",

              transform: "translateY(3px)",
            }}
          >
            <List sx={{ padding: "7px" }}>
              {conversations.map((conversation) => (
                <ListItem key={conversation.id} sx={{ padding: "1px" }}>
                  <ListItemButton
                    sx={{
                      backgroundColor:
                        conversation.id === id ? "lightgray" : "",
                      margin: "0px",
                    }}
                    onClick={() => {
                      setSelectedConvo(conversation);
                      navigate(`/mnb/czat/${conversation.id}`);
                    }}
                  >
                    <Box>
                      <Typography>
                        {moment(conversation.lastAppended).format(
                          "HH:mm DD.MM.YYYY"
                        )}
                      </Typography>
                      <Typography
                        fontSize={"12px"}
                        sx={{
                          width: "300px",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          WebkitLineClamp: "2",
                        }}
                      >
                        {conversation.snippet}
                      </Typography>
                    </Box>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Popover>
        </Box>
      </div>
      <DashboardTemplate
        leftPanel={
          <ChatFeed
            adminMode
            onSourecsChange={(values) => {
              setSources(values);
            }}
            onSourcesLoading={(v) => {}}
            focusOnInput={false}
            chatId={id ?? undefined}
          />
        }
        rightPanel={
          <div className="rightSidePanel">
            <Box
              id={"boxScrolled"}
              p={"10px"}
              style={{
                height: "100%",
                overflowY: "auto",
                display: "block",
              }}
            >
              <ListOfSources
                sources={sources}
                onRulingSelect={(source) => {}}
                onLawSelect={(source) => {}}
              />
            </Box>
          </div>
        }
      />
    </div>
  );
};

export default AdminConversationPage;
