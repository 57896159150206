import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";

export interface WhiteListUser {
  email: string;
  firstName: string;
  lastName: string;
  firm: string;
}

type Props = {
  onClick: (user: WhiteListUser) => void;
  loading: boolean;
};

const WhiteListUserAdditionPanel = ({ onClick, loading }: Props) => {
  const [whiteListUser, setWhiteListUser] = useState<WhiteListUser>({
    email: "",
    firstName: "",
    lastName: "",
    firm: "",
  });

  return (
    <Box
      width={"400px"}
      height={"500px"}
      padding="30px"
      style={{
        border: "1px solid lightGray",
        borderRadius: "5px",
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      }}
    >
      <Box width={"100%"} display={"flex"} justifyContent={"center"}>
        <Typography fontWeight={"600"}>
          {" "}
          Dodaj nowego użytkownika do WhiteList
        </Typography>
      </Box>
      <Box
        width={"100%"}
        display={"flex"}
        justifyContent={"center"}
        marginTop={"20px"}
        flexDirection={"column"}
      >
        <Typography variant={"body2"}>Email</Typography>
        <TextField
          size={"small"}
          fullWidth
          sx={{ marginBottom: "20px" }}
          value={whiteListUser.email}
          onChange={(e) => {
            setWhiteListUser({ ...whiteListUser, email: e.target.value });
          }}
          placeholder="podaj adres email"
        />
        <Typography variant={"body2"}>Imię</Typography>
        <TextField
          size={"small"}
          fullWidth
          value={whiteListUser.firstName}
          sx={{ marginBottom: "20px" }}
          onChange={(e) => {
            setWhiteListUser({ ...whiteListUser, firstName: e.target.value });
          }}
          placeholder="podaj imię (opcjonalnie)"
        />
        <Typography variant={"body2"}>Nazwisko</Typography>
        <TextField
          size={"small"}
          fullWidth
          value={whiteListUser.lastName}
          sx={{ marginBottom: "20px" }}
          onChange={(e) => {
            setWhiteListUser({ ...whiteListUser, lastName: e.target.value });
          }}
          placeholder="podaj nazwisko (opcjonalnie)"
        />
        <Typography variant={"body2"}>Firma</Typography>
        <TextField
          size={"small"}
          fullWidth
          value={whiteListUser.firm}
          sx={{ marginBottom: "10px" }}
          onChange={(e) => {
            setWhiteListUser({ ...whiteListUser, firm: e.target.value });
          }}
          placeholder="podaj firmę (opcjonalnie)"
        />
      </Box>
      <Box
        width={"100%"}
        display={"flex"}
        justifyContent={"center"}
        marginTop={"20px"}
      >
        <Button
          disabled={whiteListUser.email.trim() === ""}
          fullWidth
          variant="contained"
          onClick={() => {
            onClick(whiteListUser);
          }}
        >
          {loading ? (
            <CircularProgress size={20} color={"inherit"} />
          ) : (
            "Utwórz użytkownika"
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default WhiteListUserAdditionPanel;
