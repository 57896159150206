import { Box, Divider, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export interface UserElement {
  id: string;
  email: string;
  name: string;
}

export interface ConversationData {
  id: string;
  lastAppended: Date | null;
  snippet: string;
}

type Props = {
  user: UserElement;
  conversationsList: ConversationData[];
};

const UserDetailsPanel = ({ user, conversationsList }: Props) => {
  const navigate = useNavigate();

  return (
    <Box width={"100%"} height={"100%"}>
      <Typography variant="h6">{user.email}</Typography>
      <Box>
        <Typography variant="h6">Konwersacje</Typography>
        <Box height={"calc(100vh - 130px)"} sx={{ overflow: "auto" }}>
          {conversationsList.map((conversation) => (
            <Box>
              <Box
                key={conversation.id}
                width={"100%"}
                padding={"5px"}
                onClick={() => {
                  navigate(`/mnb/czat/${conversation.id}`);
                }}
                sx={{
                  cursor: "pointer",
                  "&:hover": { backgroundColor: "lightGray" },
                }}
              >
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {conversation.snippet}
                </Typography>
                <Typography fontSize={"12px"}>{conversation.id}</Typography>
                <Typography>{conversation.lastAppended?.toString()}</Typography>
              </Box>
              <Divider />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default UserDetailsPanel;
