import { collection, getDocs, query } from "firebase/firestore";
import { firestore } from "../../../configs/firebaseConfig";
import { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";

const WhiteListPanel = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<string[]>([]);

  const getUsers = async () => {
    setLoading(true);
    const querySnapshot = await getDocs(
      query(collection(firestore, "WhiteList"))
    );

    const usersList: { email: string; createdAt: Date }[] = querySnapshot.docs
      .map((doc) => {
        console.log(doc.data().createdAt);
        return {
          email: doc.data().email,
          createdAt: doc.data().creationDate?.seconds ?? 0,
        };
      })
      .sort((a, b) => b.createdAt - a.createdAt);
    console.log("usersList");
    console.log(usersList);
    setUsers(usersList.map((user) => user.email));
    setLoading(false);
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Box
      width={"400px"}
      height={"500px"}
      padding="30px"
      style={{
        overflowY: "auto",
        border: "1px solid lightGray",
        borderRadius: "5px",
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      }}
    >
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <div>
          {users.map((user, index) => {
            return (
              <div key={index} style={{ marginBottom: "7px" }}>
                {user}
              </div>
            );
          })}
        </div>
      )}
    </Box>
  );
};

export default WhiteListPanel;
