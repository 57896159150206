import { collection, getDocs, query, where } from "firebase/firestore";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { firestore } from "../../../configs/firebaseConfig";
import UserDetailsPanel, {
  ConversationData,
  UserElement,
} from "./UserDetailsPanel";

const AdminUsersPage = () => {
  const [loadingRight, setLoadingRight] = useState(false);
  const [users, setUsers] = useState<UserElement[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserElement | null>(null);
  const [searchedText, setSearchedText] = useState("");
  const [conversations, setConversations] = useState<ConversationData[]>([]);

  const handleUserClick = (user: UserElement) => {
    setSelectedUser(user);
  };

  const getUsersList = async () => {
    const querySnapshot = await getDocs(query(collection(firestore, "Users")));

    const usersList: UserElement[] = querySnapshot.docs.map((doc) => {
      return {
        email: doc.data().email,
        id: doc.id,
        name: "",
      };
    });
    let filteredUsers = usersList;
    if (searchedText !== "") {
      filteredUsers = usersList.filter((user) =>
        user.email.toLowerCase().includes(searchedText.toLowerCase())
      );
    }

    setUsers(filteredUsers);
  };

  const getUserDetails = async (id: string) => {
    setLoadingRight(true);
    const q = query(
      collection(firestore, "Conversations"),
      where("uid", "==", id)
    );

    const querySnapshot = await getDocs(q);
    const conversationsList: ConversationData[] = querySnapshot.docs.map(
      (doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          lastAppended: data.lastAppended ? data.lastAppended.toDate() : null,
          snippet: data.messages[1].content,
        };
      }
    );
    setConversations(conversationsList);
    setLoadingRight(false);
  };

  useEffect(() => {
    getUsersList();
    //eslint-disable-next-line
  }, [searchedText]);

  useEffect(() => {
    if (selectedUser != null) {
      getUserDetails(selectedUser.id).then((user) => {
        // setSelectedUser(user);
      });
    }
    //eslint-disable-next-line
  }, [selectedUser]);

  return (
    <Grid container>
      <Grid
        item
        xs={3}
        sx={{
          maxHeight: "calc(100vh - 20px)",
          borderRight: "1px solid lightGray",
        }}
      >
        <TextField
          placeholder="szukaj"
          fullWidth
          value={searchedText}
          onChange={(e) => {
            setSearchedText(e.target.value);
          }}
        />
        <Divider />
        <Box sx={{ overflow: "auto", height: "calc(100% - 40px)" }}>
          {users.map((m) => {
            return (
              <>
                <Button
                  sx={{
                    width: "100%",
                    textAlign: "left",
                    textTransform: "none",
                    justifyContent: "flex-start",
                    height: "50px",
                    backgroundColor:
                      selectedUser?.email === m.email
                        ? "lightgray"
                        : "transparent",
                    "&:hover": {
                      backgroundColor: "lightgray",
                    },
                  }}
                  onClick={() => handleUserClick(m)}
                >
                  {m.email}
                </Button>
                <Divider />
              </>
            );
          })}
        </Box>
      </Grid>

      <Grid item xs={9} padding={"20px"}>
        {loadingRight ? (
          <>
            <CircularProgress size={20} />
          </>
        ) : (
          <>
            {selectedUser != null ? (
              <UserDetailsPanel
                user={selectedUser}
                conversationsList={conversations}
              />
            ) : (
              <Typography variant="h6">Wybierz użytkownika</Typography>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default AdminUsersPage;
